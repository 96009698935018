<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'personal-dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'my-leaves' }">My Leaves</router-link>
        </li>
        <li class="breadcrumb-item active">Edit</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
        </div>
        <div class="col-md-1 text-right">
          <router-link
            :to="{ name: 'my-leaves' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h2>Finalize Leave Request</h2>
              </div>
              <div class="card-body">
                <div class="mb-3">
                    <label class="form-label">Leave Group</label>
                    <b-form-select
                    class="form-select" :class="{ 'form-group--error': $v.form.type.$error }"
                    aria-label="Default select example"
                    v-model="$v.form.type.$model"
                    :validator="$v.form.type"
                    :options="this.leaveTypes"
                    @change="setLeaveType"
                    ></b-form-select>
                    <span
                    class="error "
                    v-if="$v.form.type.$error == true"
                    >Leave Group is required</span>
                </div>
                
                <div class="row mb-3 request-leave">
                  <div class="col-md-12">
                    <label class="form-label">Leave Type</label>
                    <div class="form-group">
                      <b-form-radio
                          id="short_leave"
                          v-model="form.leave_type"
                          type="radio"
                          value="SHORT LEAVE"
                          required
                      >Short leave</b-form-radio>
                      <b-form-radio 
                          id="half_day"
                          v-model="form.leave_type"
                          type="radio"
                          value="HALF DAY"
                          required
                      >Half day</b-form-radio>
                      <b-form-radio 
                          id="full_day"
                          v-model="form.leave_type"
                          type="radio"
                          value="FULL DAY"
                          required
                      >Full day</b-form-radio>
                      <p
                      class="error "
                      v-if="$v.form.leave_type.$error == true"
                      >Leave Type is required</p
                    >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Leave From</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="this.form.from"
                      />
                    </div>
                  </div>
                  <div class="mb-3 col-md-6">
                      <label class="form-label">Start Time</label>
                      <div class="">
                      <vue-timepicker
                        format="hh:mm A"
                        v-model="$v.form.start_time.$model"
                        :validator="$v.form.start_time"
                        auto-scroll
                        @change="timeChangeHandler($event,'start_time')"
                      ></vue-timepicker>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Leave To</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="this.form.to"
                      />
                    </div>
                  </div>
                  <div class="mb-3 col-md-6">
                      <label class="form-label">End Time</label>
                      <div class="">
                      <vue-timepicker
                        format="hh:mm A"
                        v-model="$v.form.end_time.$model"
                        :validator="$v.form.end_time"
                        auto-scroll
                        @change="timeChangeHandler($event,'end_time')"
                      ></vue-timepicker>
                      </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Return Date</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="this.form.returnDate" 
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Reason</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="this.form.reason"
                  />
                </div>

                <div class="text-right">
                    <button type="submit" class="btn btn-outline-primary space" >Submit request</button>
                    <router-link :to="{name: 'my-leaves'}" class="btn btn-outline-primary" >Cancel</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios';
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: ["id"],
  components: {VueTimepicker},
  data() {
    return {
      form: {
        id: null,
        employee: null,
        type: null,
        leave_type: null,
        start_time: null,
        end_time: null,
        from: null,
        to: null,
        returnDate: null,
        reason: null,
        status: null
      },
    };
  },
  validations: {
    form: {
      status:{
          required
      },
      type: {
          required
      },
      leave_type:{
        required
      },
      start_time:{

      },
      end_time:{

      }
    },
  },
  created() {
    this.$store.dispatch('switchLoader',true);
      this.$store.dispatch("loadMyLeaves");
  },
  mounted() {
     let empData = this.$store.state.myLeavesData.l.filter((leave) => {
         return leave.id == this.$route.params.id;
      })[0];
    
    
    this.form.id = empData.id;
    this.form.employee = empData.employee;
    this.form.type = empData.type
    this.form.leave_type = empData.leave_type;
    this.form.from = empData.leavefrom;
    this.form.to = empData.leaveto;
    this.form.start_time = empData.start_time;
    this.form.end_time = empData.end_time;
    this.form.returnDate = empData.returndate;
    this.form.reason = empData.reason;
    this.form.status = 'Pending';
  },
 computed: {
     leaveTypes(){
        return this.$store.state.myLeavesData.lt.map((type)=>{
            return {
                value: type.leavetype,
                text: type.leavetype,
            }
        });
    }
 },
  methods: {
    setLeaveType(){
        let lt = this.$store.state.myLeavesData.lt.filter((type) => {
            return type.leavetype = this.form.type;
        })[0];
        this.form.typeid = lt.id;
    },
    timeChangeHandler(event,time){
        if(time == 'start_time'){
            this.form.start_time = event.displayTime;
        }
        else{
            this.form.end_time = event.displayTime;
        }
        
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("/leaves/update", this.form)
        .catch(({ response }) => {
          console.log(response);
          this.flashMessage.error({
            message: "Something went wrong!",
            time: 3000,
          });
        })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "my-leaves" });
          } else {
            this.flashMessage.error({
              message: data.error,
              time: 3000,
            });
          }
        });
    },
  },
};
</script>